<template>
  <section class="app-main" :class="{homeAppMain:!isShowHomeStyle,overviewAppMain:isShowOverviewStyle}">
    <transition name="fade-transform" mode="out-in" v-if="$route.path == '/dashboard' || $route.path == '/safety' || $route.path == '/overview'">
      <keep-alive v-if="$store.state.tagsView">
        <router-view :key="key" />
      </keep-alive>
      <router-view v-if="!$store.state.tagsView" :key="key" />
    </transition>
    <el-card class="box-card container" v-else>
      <transition name="fade-transform" mode="out-in">
        <keep-alive v-if="$store.state.tagsView">
          <router-view :key="key" />
        </keep-alive>
        <!-- <router-view v-if="!$store.state.tagsView" :key="key" /> -->
      </transition>
    </el-card>
    <div class="watermask">
        <waterMask></waterMask>
    </div>
  </section>
</template>

<script>
import waterMask from './waterMark.vue';	// 文件在同级下
export default {
  components: {
    waterMask
  },
  name: "AppMain",
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key() {
      return this.$route.path + new Date().getTime();
    },
    isShowHomeStyle() {
      return this.$route.path == '/dashboard' || this.$route.path == '/safety' || this.$route.path == '/overview';
    },
    isShowOverviewStyle() {
      return this.$route.path == '/overview';
    }
  },
  mounted() {
    console.log(this.$store.state.tagsView)
  }
};
</script>

<style lang="scss">
.homeAppMain{
  background: #f0f2f5;
}
.overviewAppMain{
  background: #142155;
  padding: 0px !important;
}
.app-main {
  /* 50= navbar  50  */
  height: calc(100vh - 50px);
  background-color: black;
  // height: 100vh;
  // padding: 1px;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  /* background: #f0f2f5; */
  .container {
    border:none;
    height: calc(100vh - 50px);
    overflow-y: auto;
  }
  .fixed-header + .app-main {
    padding-top: 50px;
    height: 100vh;
    overflow: auto;
  }

  .hasTagsView {
    .app-main {
      /* 84 = navbar + tags-view = 50 + 34 */
      min-height: calc(100vh - 84px);
    }

    .fixed-header + .app-main {
      padding-top: 84px;
    }
  }
  ::-webkit-scrollbar {
    width: 8px; // 横向滚动条
    height: 8px; // 纵向滚动条 必写
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #ededed;
  }
  // 滚动条的滑块
  ::-webkit-scrollbar-thumb {
    background-color: #c0c0c0;
    border-radius: 4px;
  }
}
</style>
<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
<style scoped>
/deep/ .el-card__body {
  padding: 1px;
}
</style>
