import request from '@/utils/request';


//打赏类别列表
export function queryTypeList(data) {
  return request({
    url: '/web/reward/type/list',
    method: 'post',
    data
  });
}


//打赏内容列表
export function queryContentList(data) {
  return request({
    url: '/web/reward/content/list',
    method: 'post',
    data
  });
}

//获取当前辖区打赏类别
export function rewardType(){
  return request({
    url: '/web/reward/rewardType',
    method: 'get'
  });
}


//获取打赏类别详情
export function rewardTypeDetail(id){
  return request({
    url: '/web/reward/rewardTypeDetail/' + id,
    method: 'get'
  });
}





export function getRewardRecordV2(params){
  return request({
    url: '/web/reward/rewardTypeV2',
    method: 'get',
    params: params
  });
}

//获取当指定类别的打赏内容
export function rewardContent(rewardTypeId){
  return request({
    url: '/web/reward/rewardContent?rewardTypeId='+rewardTypeId,
    method: 'get'
  });
}
//警情标签导入模板
export function importRewardContentBatch() {
  return request({
    url: '/app/v1/reward/importRewardContentBatch',
    method: 'post',
    responseType: 'blob'
  });
}

//新增打赏类别
export function saveType(data){
  return request({
    url: '/web/reward/saveType',
    method: 'post',
    data
  });
}

//新增打赏内容
export function saveContent(data){
  return request({
    url: '/web/reward/saveContent',
    method: 'post',
    data
  });
}

//修改打赏类别
export function updateType(data){
  return request({
    url: '/web/reward/updateType',
    method: 'post',
    data
  });
}
//批量修改打赏类别
export function updateTypeBatch(data){
  return request({
    url: '/web/reward/updateTypeBatch',
    method: 'post',
    data
  });
}
//批量修改打赏类别
export function deleteTypeList(data){
  return request({
    url: '/web/reward/deleteTypeList',
    method: 'post',
    data
  });
}
//获取打赏内容
export function rewardContentDetail(data){
  return request({
    url: '/web/reward/rewardContentDetail/'+data,
    method: 'get',
    data
  });
}
//批量修改打赏内容
export function updateContentBatch(data){
  return request({
    url: '/web/reward/updateContentBatch',
    method: 'post',
    data
  });
}
//修改打赏内容
export function updateContent(data){
  return request({
    url: '/web/reward/updateContent',
    method: 'post',
    data
  });
}

//获取当指定类别的打赏内容
export function saveReward(data){
  return request({
    url: '/web/reward/save',
    method: 'post',
    data
  });
}

//获取打赏分数区间
export function rewardPointRange(rewardContentId){
  return request({
    url: '/web/reward/rewardPointRange?rewardContentId='+rewardContentId,
    method: 'get'
  });
}

export function getRewardRecord(params){
  return request({
    url: '/web/reward/rewardRecord',
    method: 'get',
    params: params
  });
}
