var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Treeselect", {
    attrs: {
      options: _vm.options,
      multiple: _vm.multiple,
      placeholder: _vm.placeholder,
      normalizer: _vm.tenantIdnormalizer,
      autoSelectDescendants: false,
      flat: true,
      clearable: false,
    },
    on: {
      input: _vm.treeSelectInput,
      select: _vm.treeSelectChange,
      deselect: _vm.treeSelectDeselect,
      "search-change": _vm.treeSelectSearch,
      open: _vm.treeSelectOpen,
      close: _vm.treeSelectClose,
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v
      },
      expression: "value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }