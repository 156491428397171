/**大屏proMax */
import request from '@/utils/request';

//获取警情流转下发任务辖区数据
export function getCustomDeptViewId(deptId) {
  return request({
    url: 'app/v1/dept/getCustomDeptViewId?deptId=' + deptId,
    method: 'get',
  });
}

//树点击获取辖区下的数据
export function getParentDeptName(id) {
  return request({
    url: '/web/dept/getInfoById/' + id,
    method: 'get',
  });
}

//大屏图标显示
export function getOverviewContentV3() {
  return request({
    url: '/web/v2/dashboardV3/getOverviewContentV3 ',
    method: 'get',
  });
}

// 人员/人员信息
export function getUserCount(deptId) {
  return request({
    url: '/web/v2/dashboardV3/userCountV3?deptId=' + deptId,
    method: 'get',
  });
}

// 人员/用户身份
export function getUserCategory(deptId) {
  return request({
    url: '/web/v2/dashboardV3/findUserCategory?deptId=' + deptId,
    method: 'get',
  });
}

// **人员/人员情况**
export function findUserType(data) {
  return request({
    url: '/web/v2/dashboardV3/findUserType',
    method: 'post',
    data,
  });
}

// 车辆/车辆信息
export function getBikeCount(deptId) {
  return request({
    url: '/web/v2/dashboardV3/bikeCountV3?deptId=' + deptId,
    method: 'get',
  });
}

// **车辆/车辆情况**
export function getBikeCountType(deptId) {
  return request({
    url: '/web/v2/dashboardV3/bikeCountTypeV3?deptId=' + deptId,
    method: 'get',
  });
}

// 固定点位/固定点位信息
export function getFixedPointByDeptIdCount(deptId) {
  return request({
    url: '/web/v2/dashboardV3/getFixedPointByDeptIdCountV3?deptId=' + deptId,
    method: 'get',
  });
}

// **固定点位/固定点位情况**
export function getFixedPointByDeptIdCountCondition(deptId) {
  return request({
    url:
      '/web/v2/dashboardV3/getFixedPointByDeptIdCountCondition?deptId=' +
      deptId,
    method: 'get',
  });
}

// 任务创建/任务信息
export function getTaskCount(deptId) {
  return request({
    url: '/web/v2/dashboardV3/getTaskCountV3?deptId=' + deptId,
    method: 'get',
  });
}

// **任务创建/任务情况**
export function getTaskCountCondition(deptId) {
  return request({
    url: '/web/v2/dashboardV3/getTaskCountCondition?deptId=' + deptId,
    method: 'get',
  });
}

// **任务创建/指定类型数据**
export function getTaskLabelCountCondition(deptId) {
  return request({
    url: '/web/v2/dashboardV3/getTaskLabelCountCondition?deptId=' + deptId,
    method: 'get',
  });
}

// 实时动态/实时动态
export function getRealTimeKinematic(deptId) {
  return request({
    url: '/web/v2/dashboardV3/realTimeKinematicV3?deptId=' + deptId,
    method: 'get',
  });
}

// 人员数据详情弹窗
export function getUserDetail(data) {
  return request({
    url: '/web/v2/dashboardV3/userListContent',
    method: 'post',
    data,
  });
}

// 车辆数据详情弹窗
export function getCarDetail(data) {
  return request({
    url: '/web/v2/dashboardV3/bikeListContent',
    method: 'post',
    data,
  });
}

// 车辆数据详情弹窗(定位)
export function getCarDetailLoaction(data) {
  return request({
    url: '/web/v2/dashboardV3/onlineOneBikeDetailV3',
    method: 'post',
    data,
  });
}

// 固定点位数据详情弹窗
export function getFixedPointDetail(data) {
  return request({
    url: '/web/v2/dashboardV3/fixedListContent',
    method: 'post',
    data,
  });
}

// 固定点位数据详情弹窗(定位)
export function getPointlLoaction(data) {
  return request({
    url: '/web/bike/getChecFixedPointByDeptIdTypeOne',
    method: 'post',
    data,
  });
}

// 任务数据详情弹窗
export function getTaskDetail(data) {
  return request({
    url: '/web/v2/dashboardV3/getTaskContentList',
    method: 'post',
    data,
  });
}
export function getTaskDetailV2(data) {
  return request({
    url: '/web/v2/dashboardV3/getTaskContentListV2',
    method: 'post',
    data,
  });
}
