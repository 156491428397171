var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { display: "flex" } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { flex: "1" },
              attrs: { model: _vm.form, "label-width": "90px" },
            },
            [
              _c("el-form-item", { attrs: { label: "反映辖区：" } }, [
                _c(
                  "div",
                  {
                    staticClass: "content",
                    staticStyle: { display: "flex", "align-items": "center" },
                  },
                  [
                    _c("span", [_vm._v(" " + _vm._s(_vm.form.deptName) + " ")]),
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          effect: "dark",
                          content: _vm.form.deptAllName,
                          placement: "top-start",
                        },
                      },
                      [
                        _c("img", {
                          staticStyle: { "margin-left": "10px" },
                          attrs: {
                            src: require("./infoCircle.png"),
                            width: "15",
                            alt: "",
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-form-item", { attrs: { label: "事件类型：" } }, [
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.form.typeName)),
                ]),
              ]),
              _c("el-form-item", { attrs: { label: "事件标签：" } }, [
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.form.labelName)),
                ]),
              ]),
              _c("el-form-item", { attrs: { label: "事件内容：" } }, [
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.form.submitDesc)),
                ]),
              ]),
              _vm.submitFileUrls.length > 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "事件图片：" } },
                    _vm._l(_vm.submitFileUrls, function (item, idx) {
                      return _c("el-image", {
                        key: idx,
                        staticStyle: {
                          width: "100px",
                          height: "100px",
                          "z-index": "2021",
                        },
                        attrs: {
                          src: item,
                          "preview-src-list": _vm.submitFileUrls,
                        },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm.submitVideoFileUrls.length > 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "事件视频：" } },
                    _vm._l(_vm.submitVideoFileUrls, function (item, idx) {
                      return _c("div", { key: idx }, [
                        _c("video", {
                          staticStyle: { width: "200px", height: "200px" },
                          attrs: {
                            src: item,
                            controls: "controls",
                            crossOrigin: "Anonymous",
                          },
                        }),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
              _vm.submitAudioFileUrls.length > 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "事件音频：" } },
                    _vm._l(_vm.submitAudioFileUrls, function (item, idx) {
                      return _c("div", { key: idx }, [
                        _c("audio", {
                          staticClass: "nowAudio",
                          attrs: { src: item, controls: "controls" },
                        }),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
              _c("el-form-item", { attrs: { label: "反映人：" } }, [
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.form.username)),
                ]),
              ]),
              _c("el-form-item", { attrs: { label: "事件地址：" } }, [
                _vm.form.detailedAddress
                  ? _c("div", { staticClass: "content" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.form.address) +
                          " (" +
                          _vm._s(_vm.form.detailedAddress) +
                          ") "
                      ),
                    ])
                  : _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.form.address)),
                    ]),
              ]),
              _c("el-form-item", { attrs: { label: "联系电话：" } }, [
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.form.submitTel)),
                ]),
              ]),
              _c("el-form-item", { attrs: { label: "反映时间：" } }, [
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.form.createTime)),
                ]),
              ]),
              _c("br"),
            ],
            1
          ),
          _c("div", { staticStyle: { flex: "1", padding: "10px" } }, [
            _c("h2", { staticStyle: { color: "rgba(0, 0, 0, 0.9)" } }, [
              _vm._v(" 处理进度 - "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: this.form.status === 1,
                      expression: "this.form.status === 1",
                    },
                  ],
                },
                [_vm._v("待处理")]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: this.form.status === 2,
                      expression: "this.form.status === 2",
                    },
                  ],
                },
                [_vm._v("已签收")]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: this.form.status === 12,
                      expression: "this.form.status === 12",
                    },
                  ],
                },
                [_vm._v("已驳回")]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: this.form.status === 10,
                      expression: "this.form.status === 10",
                    },
                  ],
                },
                [_vm._v("已移交")]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: this.form.status === 14,
                      expression: "this.form.status === 14",
                    },
                  ],
                },
                [_vm._v("已撤销")]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: this.form.status === 18,
                      expression: "this.form.status === 18",
                    },
                  ],
                },
                [_vm._v("已处理")]
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "Processing",
                style: { height: _vm.myDivHeight + "px" },
              },
              [
                _vm._l(_vm.form.feedbackDtoList, function (item, index) {
                  return item.feedbackContent.length > 0
                    ? _c(
                        "div",
                        { key: index },
                        [
                          _c("label", [_vm._v(_vm._s(item.feedbackUserName))]),
                          _c("br"),
                          _c("br"),
                          _vm._v(" " + _vm._s(item.feedbackContent) + " "),
                          _c("br"),
                          _vm._l(item.feedbackFileUrls, function (img, idx) {
                            return _c("el-image", {
                              key: idx,
                              staticStyle: {
                                width: "100px",
                                height: "100px",
                                "z-index": "2021",
                                "margin-top": "10px",
                              },
                              attrs: {
                                src: img,
                                "preview-src-list": item.feedbackFileUrls,
                              },
                            })
                          }),
                        ],
                        2
                      )
                    : _vm._e()
                }),
                _c("div", { staticClass: "bootContent" }, [
                  this.form.status != 14
                    ? _c(
                        "div",
                        [
                          _c(
                            "div",
                            { ref: "stepItem", staticClass: "stepItem" },
                            _vm._l(_vm.a, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  class: { bdn: _vm.c.length == 0 },
                                },
                                [
                                  _c("div", { staticClass: "stepBox" }, [
                                    _c("div", { staticClass: "Item1205s" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "deptName1205s",
                                          staticStyle: {
                                            "margin-bottom": "10px",
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.deptName)),
                                          ]),
                                        ]
                                      ),
                                      item.hasOwnProperty("handleTime")
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "time1205s",
                                              staticStyle: {
                                                "font-size": "12px",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: item.status === 1,
                                                      expression:
                                                        "item.status === 1",
                                                    },
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    "待处理·" +
                                                      _vm._s(
                                                        _vm.setTime(
                                                          item.handleTime
                                                        )
                                                      )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: item.status === 2,
                                                      expression:
                                                        "item.status === 2",
                                                    },
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    "已签收·" +
                                                      _vm._s(
                                                        _vm.setTime(
                                                          item.handleTime
                                                        )
                                                      )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: item.status === 12,
                                                      expression:
                                                        "item.status === 12",
                                                    },
                                                  ],
                                                  staticStyle: { color: "red" },
                                                },
                                                [
                                                  _vm._v(
                                                    "已驳回·" +
                                                      _vm._s(
                                                        _vm.setTime(
                                                          item.handleTime
                                                        )
                                                      )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: item.status === 10,
                                                      expression:
                                                        "item.status === 10",
                                                    },
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    "已移交·" +
                                                      _vm._s(
                                                        _vm.setTime(
                                                          item.handleTime
                                                        )
                                                      )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: item.status === 14,
                                                      expression:
                                                        "item.status === 14",
                                                    },
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    "已撤销·" +
                                                      _vm._s(
                                                        _vm.setTime(
                                                          item.handleTime
                                                        )
                                                      )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        item.status === 18 &&
                                                        item.benren,
                                                      expression:
                                                        "item.status === 18 && item.benren",
                                                    },
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.benren
                                                        ? item.benren
                                                        : "已处理"
                                                    ) +
                                                      " · " +
                                                      _vm._s(
                                                        _vm.setTime(
                                                          item.handleTime
                                                        )
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    item.status == 18 &&
                                    item.hasOwnProperty("handleRemark") &&
                                    item.handleRemark != "null"
                                      ? _c(
                                          "div",
                                          { staticClass: "handleRemark1205s" },
                                          [
                                            item.processMode == 1
                                              ? _c("span", [
                                                  _vm._v("处理方式："),
                                                ])
                                              : _vm._e(),
                                            _vm._v(
                                              _vm._s(item.handleRemark) + " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    item.status == 18 &&
                                    _vm.rewardRecord.id &&
                                    item.handleRemark
                                      ? _c("div", { staticClass: "gagaga" }, [
                                          _c(
                                            "div",
                                            { staticClass: "Itemgaga" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "deptNamegaga" },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.rewardRecord
                                                          .sysDeptName
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "timegaga",
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            item.status === 18,
                                                          expression:
                                                            "item.status === 18",
                                                        },
                                                      ],
                                                    },
                                                    [
                                                      _vm._v(
                                                        "打赏积分·" +
                                                          _vm._s(
                                                            _vm.setTime(
                                                              _vm.rewardRecord
                                                                .createTime
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "handleRemarkGaga" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.rewardRecord.rewardPoint
                                                  ) +
                                                  "分：" +
                                                  _vm._s(
                                                    _vm.rewardRecord
                                                      .rewardContent
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                    item.status == 12 &&
                                    item.hasOwnProperty("handleRemark") &&
                                    item.handleRemark != "null"
                                      ? _c(
                                          "div",
                                          { staticClass: "handleRemark1205s" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.handleRemark) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    item.status == 10 &&
                                    item.hasOwnProperty("handleRemark") &&
                                    item.handleRemark != "null"
                                      ? _c(
                                          "div",
                                          { staticClass: "handleRemark1205s" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.handleRemark) +
                                                " "
                                            ),
                                            _c("br"),
                                            _c("br"),
                                            _vm._v(" 接收单位: "),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: { color: "black" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(item.turnOverDeptName)
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                          _vm._l(_vm.c, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "stepItem",
                                class: { bdn: index == _vm.c.length - 1 },
                              },
                              [
                                _c("div", { staticClass: "stepBox" }, [
                                  _c("div", { staticClass: "Item1205s" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "deptName1205s",
                                        staticStyle: {
                                          "margin-bottom": "10px",
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(item.deptName)),
                                        ]),
                                      ]
                                    ),
                                    item.hasOwnProperty("handleTime")
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "time1205s",
                                            staticStyle: {
                                              "font-size": "12px",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: item.status === 1,
                                                    expression:
                                                      "item.status === 1",
                                                  },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  "待处理·" +
                                                    _vm._s(
                                                      _vm.setTime(
                                                        item.handleTime
                                                      )
                                                    )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: item.status === 2,
                                                    expression:
                                                      "item.status === 2",
                                                  },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  "已签收·" +
                                                    _vm._s(
                                                      _vm.setTime(
                                                        item.handleTime
                                                      )
                                                    )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: item.status === 12,
                                                    expression:
                                                      "item.status === 12",
                                                  },
                                                ],
                                                staticStyle: { color: "red" },
                                              },
                                              [
                                                _vm._v(
                                                  "已驳回·" +
                                                    _vm._s(
                                                      _vm.setTime(
                                                        item.handleTime
                                                      )
                                                    )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: item.status === 10,
                                                    expression:
                                                      "item.status === 10",
                                                  },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  "已移交·" +
                                                    _vm._s(
                                                      _vm.setTime(
                                                        item.handleTime
                                                      )
                                                    )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: item.status === 14,
                                                    expression:
                                                      "item.status === 14",
                                                  },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  "已撤销·" +
                                                    _vm._s(
                                                      _vm.setTime(
                                                        item.handleTime
                                                      )
                                                    )
                                                ),
                                              ]
                                            ),
                                            item.status === 18
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.benren
                                                        ? item.benren
                                                        : "已处理"
                                                    ) +
                                                      " · " +
                                                      _vm._s(
                                                        _vm.setTime(
                                                          item.handleTime
                                                        )
                                                      )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                  item.status == 18 &&
                                  item.hasOwnProperty("handleRemark") &&
                                  item.handleRemark != "null"
                                    ? _c(
                                        "div",
                                        { staticClass: "handleRemark1205s" },
                                        [
                                          item.processMode == 1
                                            ? _c("span", [_vm._v("处理方式：")])
                                            : _vm._e(),
                                          _vm._v(
                                            _vm._s(item.handleRemark) + " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  item.status == 18 &&
                                  _vm.rewardRecord.id &&
                                  item.handleRemark
                                    ? _c("div", { staticClass: "gagaga" }, [
                                        _c("div", { staticClass: "Itemgaga" }, [
                                          _c(
                                            "div",
                                            { staticClass: "deptNamegaga" },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.rewardRecord.sysDeptName
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "timegaga",
                                              staticStyle: {
                                                "font-size": "12px",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: item.status === 18,
                                                      expression:
                                                        "item.status === 18",
                                                    },
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    "打赏积分·" +
                                                      _vm._s(
                                                        _vm.setTime(
                                                          _vm.rewardRecord
                                                            .createTime
                                                        )
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "handleRemarkGaga" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.rewardRecord.rewardPoint
                                                ) +
                                                "分：" +
                                                _vm._s(
                                                  _vm.rewardRecord.rewardContent
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  item.status == 12 &&
                                  item.hasOwnProperty("handleRemark") &&
                                  item.handleRemark != "null"
                                    ? _c(
                                        "div",
                                        { staticClass: "handleRemark" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.handleRemark) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  item.status == 10 &&
                                  item.hasOwnProperty("handleRemark") &&
                                  item.handleRemark != "null"
                                    ? _c(
                                        "div",
                                        { staticClass: "handleRemark1205s" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.handleRemark) +
                                              " "
                                          ),
                                          _c("br"),
                                          _c("br"),
                                          _vm._v(" 接收单位: "),
                                          _c(
                                            "span",
                                            { staticStyle: { color: "black" } },
                                            [
                                              _vm._v(
                                                _vm._s(item.turnOverDeptName)
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            )
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ]),
              ],
              2
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "right",
            "margin-top": "10px",
          },
        },
        [
          _vm.typedata.process !== 2 && _vm.typedata.sign !== 2
            ? _c(
                "div",
                [
                  _vm.rowData.status == 18 ||
                  _vm.rowData.status == 14 ||
                  _vm.rowData.status == 12
                    ? _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.dialogVisible = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      )
                    : _vm._e(),
                  _vm.rowData.status == 18
                    ? _c("el-button", { attrs: { type: "primary" } }, [
                        _vm._v("确 认"),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            [
              _vm.typedata.sign == 1
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "info" },
                      on: {
                        click: function ($event) {
                          return _vm.toggle()
                        },
                      },
                    },
                    [_vm._v("移交事件")]
                  )
                : _vm._e(),
              _vm.typedata.sign == 1
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          _vm.rejectDialog = true
                        },
                      },
                    },
                    [_vm._v("驳 回")]
                  )
                : _vm._e(),
              _vm.typedata.sign == 1
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.signFor(
                            _vm.rowData.without_repetition_id,
                            _vm.rowData.id
                          )
                        },
                      },
                    },
                    [_vm._v("签 收")]
                  )
                : _vm._e(),
              _vm.typedata.process == 1
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          _vm.OverRejectDialog = true
                        },
                      },
                    },
                    [_vm._v("本人处理")]
                  )
                : _vm._e(),
              _vm.typedata.process == 1
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submit(_vm.rowData.id)
                        },
                      },
                    },
                    [_vm._v("他人协助处理")]
                  )
                : _vm._e(),
              _vm.ziti.ziti == 1 && _vm.rowData.status == 18 && _vm.rewardButton
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.reward = true
                        },
                      },
                    },
                    [_vm._v("打赏积分")]
                  )
                : _vm._e(),
            ],
            1
          ),
          [
            _vm.ziti.ziti == 2
              ? _c("p", { staticClass: "message-text" }, [
                  _vm._v(
                    " 您当前没有此事件的处理权限，只能查看处理的进度哦～ "
                  ),
                ])
              : _vm._e(),
          ],
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          key: "12313",
          attrs: {
            title: "移交事件",
            top: "200px",
            visible: _vm.RejectPopup,
            "append-to-body": true,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.RejectPopup = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.RejectForm, "label-width": "80px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "接收单位", required: "" } },
                [
                  _c("deptTreeSelect", {
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: { Value: _vm.parentValue, options: _vm.treeData },
                    on: {
                      change: function ($event) {
                        _vm.parentValue = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "移交理由", required: "" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "500",
                      type: "textarea",
                      placeholder: "请输入移交理由",
                    },
                    model: {
                      value: _vm.RejectForm.handleRemark,
                      callback: function ($$v) {
                        _vm.$set(_vm.RejectForm, "handleRemark", $$v)
                      },
                      expression: "RejectForm.handleRemark",
                    },
                  }),
                ],
                1
              ),
              _c("br"),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    display: "flex",
                    "justify-content": "center",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.onSubmit(_vm.rowData.id)
                        },
                      },
                    },
                    [_vm._v("移交")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.RejectPopup = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          key: "1231233",
          attrs: {
            title: "打赏积分",
            top: "200px",
            visible: _vm.reward,
            "append-to-body": true,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.reward = $event
            },
            close: _vm.resetRewardForm,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.rewardForm,
                "label-width": "80px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "打赏方式" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { placeholder: "打赏方式" },
                      on: { change: _vm.rewardModeChange },
                      model: {
                        value: _vm.rewardForm.rewardMode,
                        callback: function ($$v) {
                          _vm.$set(_vm.rewardForm, "rewardMode", $$v)
                        },
                        expression: "rewardForm.rewardMode",
                      },
                    },
                    _vm._l(_vm.rewardModeDict, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.rewardForm.rewardMode == 1
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "rewardPoint", label: "打赏分数" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          model: {
                            value: _vm.pointOll,
                            callback: function ($$v) {
                              _vm.pointOll = $$v
                            },
                            expression: "pointOll",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "1" } }, [
                            _vm._v("1分"),
                          ]),
                          _c("el-radio-button", { attrs: { label: "5" } }, [
                            _vm._v("5分"),
                          ]),
                          _c("el-radio-button", { attrs: { label: "10" } }, [
                            _vm._v("10分"),
                          ]),
                          _c(
                            "el-radio-button",
                            { attrs: { label: "自定义" } },
                            [_vm._v("自定义")]
                          ),
                        ],
                        1
                      ),
                      _vm.pointOll == "自定义"
                        ? _c("el-input", {
                            attrs: { type: "number", min: 1 },
                            model: {
                              value: _vm.pointOlv2l,
                              callback: function ($$v) {
                                _vm.pointOlv2l = $$v
                              },
                              expression: "pointOlv2l",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.rewardForm.rewardMode == 1
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "rewardContent", label: "打赏内容" } },
                    [
                      _c("el-input", {
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: {
                          type: "textarea",
                          maxlength: "100",
                          placeholder: "请输入打赏内容",
                        },
                        model: {
                          value: _vm.rewardForm.rewardContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.rewardForm, "rewardContent", $$v)
                          },
                          expression: "rewardForm.rewardContent",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.rewardForm.rewardMode == 2
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "rewardType", label: "打赏类别" } },
                    [
                      _c(
                        "el-select",
                        {
                          ref: "selectRewardType",
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { placeholder: "打赏类别" },
                          on: { change: _vm.rewardTypeChange },
                          model: {
                            value: _vm.rewardForm.rewardTypeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.rewardForm, "rewardTypeId", $$v)
                            },
                            expression: "rewardForm.rewardTypeId",
                          },
                        },
                        _vm._l(_vm.rewardTypeDict, function (item) {
                          return _c("el-option", {
                            key: item.key,
                            attrs: { label: item.value, value: item.key },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.rewardForm.rewardMode == 2
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "rewardContent", label: "打赏内容" } },
                    [
                      _c(
                        "el-select",
                        {
                          ref: "selectRewardContent",
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { placeholder: "打赏内容" },
                          on: { change: _vm.rewardContentChange },
                          model: {
                            value: _vm.rewardForm.rewardContentId,
                            callback: function ($$v) {
                              _vm.$set(_vm.rewardForm, "rewardContentId", $$v)
                            },
                            expression: "rewardForm.rewardContentId",
                          },
                        },
                        _vm._l(_vm.rewardContentDict, function (item) {
                          return _c("el-option", {
                            key: item.key,
                            attrs: { label: item.value, value: item.key },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.rewardForm.rewardMode == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "打赏积分" } },
                    [
                      _c("el-input-number", {
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: { min: _vm.min, max: _vm.max },
                        model: {
                          value: _vm.rewardForm.rewardPoint,
                          callback: function ($$v) {
                            _vm.$set(_vm.rewardForm, "rewardPoint", $$v)
                          },
                          expression: "rewardForm.rewardPoint",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                display: "flex",
                "justify-content": "center",
              },
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.rewardCommit } },
                [_vm._v("提 交")]
              ),
              _c("el-button", { on: { click: _vm.disposeRewardDialog } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          key: "1231244",
          attrs: {
            title: "驳回",
            top: "200px",
            visible: _vm.rejectDialog,
            "append-to-body": true,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.rejectDialog = $event
            },
            close: function ($event) {
              _vm.handleRemark = ""
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "驳回意见", required: "" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "500",
                      type: "textarea",
                      placeholder: "填写驳回意见",
                    },
                    model: {
                      value: _vm.handleRemark,
                      callback: function ($$v) {
                        _vm.handleRemark = $$v
                      },
                      expression: "handleRemark",
                    },
                  }),
                ],
                1
              ),
              _c("br"),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    display: "flex",
                    "justify-content": "center",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitReject },
                    },
                    [_vm._v("确 定")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.handleRemark = ""
                          _vm.rejectDialog = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          key: "1231255",
          attrs: {
            title: "本人处理",
            top: "200px",
            visible: _vm.OverRejectDialog,
            "append-to-body": true,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.OverRejectDialog = $event
            },
            close: function ($event) {
              _vm.OverRejectRemark = ""
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "结束意见", required: "" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "500",
                      type: "textarea",
                      placeholder: "填写结束意见",
                    },
                    model: {
                      value: _vm.OverRejectRemark,
                      callback: function ($$v) {
                        _vm.OverRejectRemark = $$v
                      },
                      expression: "OverRejectRemark",
                    },
                  }),
                ],
                1
              ),
              _c("br"),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    display: "flex",
                    "justify-content": "center",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.OverReject },
                    },
                    [_vm._v("确 定")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.OverRejectRemark = ""
                          _vm.OverRejectDialog = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }