var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "app-main",
      class: {
        homeAppMain: !_vm.isShowHomeStyle,
        overviewAppMain: _vm.isShowOverviewStyle,
      },
    },
    [
      _vm.$route.path == "/dashboard" ||
      _vm.$route.path == "/safety" ||
      _vm.$route.path == "/overview"
        ? _c(
            "transition",
            { attrs: { name: "fade-transform", mode: "out-in" } },
            [
              _vm.$store.state.tagsView
                ? _c("keep-alive", [_c("router-view", { key: _vm.key })], 1)
                : _vm._e(),
              !_vm.$store.state.tagsView
                ? _c("router-view", { key: _vm.key })
                : _vm._e(),
            ],
            1
          )
        : _c(
            "el-card",
            { staticClass: "box-card container" },
            [
              _c(
                "transition",
                { attrs: { name: "fade-transform", mode: "out-in" } },
                [
                  _vm.$store.state.tagsView
                    ? _c("keep-alive", [_c("router-view", { key: _vm.key })], 1)
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
      _c("div", { staticClass: "watermask" }, [_c("waterMask")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }