<template>
  <div>
    <div style="display: flex">
      <el-form style="flex: 1" ref="form" :model="form" label-width="90px">
        <el-form-item label="反映辖区：">
          <div class="content" style="display: flex;align-items: center;">
            <span >
              {{ form.deptName }}
            </span>
            <el-tooltip  effect="dark" :content="form.deptAllName" placement="top-start">
               <img style="margin-left: 10px;" src="./infoCircle.png" width="15" alt="">
            </el-tooltip>
          </div>
        </el-form-item>
        <el-form-item label="事件类型：">
          <div class="content">{{ form.typeName }}</div>
        </el-form-item>
        <el-form-item label="事件标签：">
          <div class="content">{{ form.labelName }}</div>
        </el-form-item>
        <el-form-item label="事件内容：">
          <div class="content">{{ form.submitDesc }}</div>
        </el-form-item>
        <el-form-item label="事件图片：" v-if="submitFileUrls.length > 0">
          <el-image
            style="width: 100px; height: 100px; z-index: 2021"
            v-for="(item, idx) in submitFileUrls"
            :key="idx"
            :src="item"
            :preview-src-list="submitFileUrls"
          >
          </el-image>
        </el-form-item>
        <el-form-item label="事件视频：" v-if="submitVideoFileUrls.length > 0">
          <div v-for="(item, idx) in submitVideoFileUrls" :key="idx">
            <video
              style="width: 200px; height: 200px"
              :src="item"
              controls="controls"
              crossOrigin="Anonymous"
            />
          </div>
        </el-form-item>
        <el-form-item label="事件音频：" v-if="submitAudioFileUrls.length > 0">
          <div v-for="(item, idx) in submitAudioFileUrls" :key="idx">
            <audio class="nowAudio" :src="item" controls="controls"></audio>
          </div>
        </el-form-item>
        <el-form-item label="反映人：">
          <div class="content">{{ form.username }}</div>
        </el-form-item>
        <el-form-item label="事件地址：">
          <div class="content" v-if="form.detailedAddress">
            {{ form.address }} ({{ form.detailedAddress }})
          </div>
          <div class="content" v-else>{{ form.address }}</div>
        </el-form-item>
        <el-form-item label="联系电话：">
          <div class="content">{{ form.submitTel }}</div>
        </el-form-item>
        <el-form-item label="反映时间：">
          <div class="content">{{ form.createTime }}</div>
        </el-form-item>
        <br />
      </el-form>
      <div style="flex: 1; padding: 10px">
        <h2 style="color: rgba(0, 0, 0, 0.9)">
          处理进度 - <span v-show="this.form.status === 1">待处理</span>
          <span v-show="this.form.status === 2">已签收</span>
          <span v-show="this.form.status === 12">已驳回</span>
          <span v-show="this.form.status === 10">已移交</span>
          <span v-show="this.form.status === 14">已撤销</span>
          <span v-show="this.form.status === 18">已处理</span>
        </h2>
        <div class="Processing" :style="{ height: myDivHeight + 'px' }">
          <div
            v-for="(item, index) in form.feedbackDtoList"
            v-if="item.feedbackContent.length > 0"
            :key="index"
          >
            <label>{{ item.feedbackUserName }}</label>
            <br />
            <br />
            {{ item.feedbackContent }}
            <br />
            <el-image
              style="
                width: 100px;
                height: 100px;
                z-index: 2021;
                margin-top: 10px;
              "
              v-for="(img, idx) in item.feedbackFileUrls"
              :key="idx"
              :src="img"
              :preview-src-list="item.feedbackFileUrls"
            >
            </el-image>
          </div>
          <div class="bootContent">
            <div v-if="this.form.status != 14">
              <div ref="stepItem" class="stepItem">
                <div
                  v-for="(item, index) in a"
                  :key="index"
                  :class="{ bdn: c.length == 0 }"
                >
                <div class="stepBox">
                  <div class="Item1205s">
                    <div class="deptName1205s" style="margin-bottom: 10px">
                      <span>{{ item.deptName }}</span>
                    </div>
                    <div
                      class="time1205s"
                      style="font-size: 12px"
                      v-if="item.hasOwnProperty('handleTime')"
                    >
                      <span v-show="item.status === 1"
                        >待处理·{{ setTime(item.handleTime) }}</span
                      >
                      <span v-show="item.status === 2"
                        >已签收·{{ setTime(item.handleTime) }}</span
                      >
                      <span v-show="item.status === 12" style="color: red"
                        >已驳回·{{ setTime(item.handleTime) }}</span
                      >
                      <span v-show="item.status === 10"
                        >已移交·{{ setTime(item.handleTime) }}</span
                      >
                      <span v-show="item.status === 14"
                        >已撤销·{{ setTime(item.handleTime) }}</span
                      >
                      <span v-show="item.status === 18 && item.benren"
                      >{{ item.benren ?item.benren:'已处理'  }} · {{  setTime(item.handleTime) }}</span
                      >

                    </div>
                  </div>
                  <div
                    v-if="
                      item.status == 18 &&
                      item.hasOwnProperty('handleRemark') &&
                      item.handleRemark != 'null'
                    "
                    class="handleRemark1205s"
                  >
                    <span  v-if="item.processMode == 1">处理方式：</span
                    >{{ item.handleRemark }}
                  </div>
                  <div
                    class="gagaga"
                    v-if="
                      item.status == 18 && rewardRecord.id && item.handleRemark
                    "
                  >
                    <div class="Itemgaga">
                      <div class="deptNamegaga">
                        <span>{{ rewardRecord.sysDeptName }}</span>
                      </div>
                      <div class="timegaga" style="font-size: 12px">
                        <span v-show="item.status === 18"
                          >打赏积分·{{ setTime(rewardRecord.createTime) }}</span
                        >
                      </div>
                    </div>
                    <div class="handleRemarkGaga">
                      {{ rewardRecord.rewardPoint }}分：{{
                        rewardRecord.rewardContent
                      }}
                    </div>
                  </div>
                  <div
                    v-if="
                      item.status == 12 &&
                      item.hasOwnProperty('handleRemark') &&
                      item.handleRemark != 'null'
                    "
                    class="handleRemark1205s"
                  >
                    {{ item.handleRemark }}
                  </div>
                  <div
                    v-if="
                      item.status == 10 &&
                      item.hasOwnProperty('handleRemark') &&
                      item.handleRemark != 'null'
                    "
                    class="handleRemark1205s"
                  >
                    {{ item.handleRemark }}
                    <br />
                    <br />

                    接收单位:
                    <span style="color: black">{{
                      item.turnOverDeptName
                    }}</span>
                  </div>
                </div>
                </div>
              </div>
              <div
                class="stepItem"
                v-for="(item, index) in c"
                :key="index"
                :class="{ bdn: index == c.length - 1 }"
              >
                <div class="stepBox">
                  <div class="Item1205s">
                    <div class="deptName1205s" style="margin-bottom: 10px">
                      <span>{{ item.deptName }}</span>
                    </div>
                    <div
                      class="time1205s"
                      style="font-size: 12px"
                      v-if="item.hasOwnProperty('handleTime')"
                    >
                      <span v-show="item.status === 1"
                        >待处理·{{ setTime(item.handleTime) }}</span
                      >
                      <span v-show="item.status === 2"
                        >已签收·{{ setTime(item.handleTime) }}</span
                      >
                      <span v-show="item.status === 12" style="color: red"
                        >已驳回·{{ setTime(item.handleTime) }}</span
                      >
                      <span v-show="item.status === 10"
                        >已移交·{{ setTime(item.handleTime) }}</span
                      >
                      <span v-show="item.status === 14"
                        >已撤销·{{ setTime(item.handleTime) }}</span
                      >
                      <span v-if="item.status === 18"
                        >{{ item.benren ?item.benren:'已处理'  }} · {{  setTime(item.handleTime) }}</span
                      >

                    </div>
                  </div>
                  <div
                    v-if="
                      item.status == 18 &&
                      item.hasOwnProperty('handleRemark') &&
                      item.handleRemark != 'null'
                    "
                    class="handleRemark1205s"
                  >
                    <span v-if="item.processMode == 1">处理方式：</span
                    >{{ item.handleRemark }}
                  </div>
                  <div
                    class="gagaga"
                    v-if="
                      item.status == 18 && rewardRecord.id && item.handleRemark
                    "
                  >
                    <div class="Itemgaga">
                      <div class="deptNamegaga">
                        <span>{{ rewardRecord.sysDeptName }}</span>
                      </div>
                      <div class="timegaga" style="font-size: 12px">
                        <span v-show="item.status === 18"
                          >打赏积分·{{ setTime(rewardRecord.createTime) }}</span
                        >
                      </div>
                    </div>
                    <div class="handleRemarkGaga">
                      {{ rewardRecord.rewardPoint }}分：{{
                        rewardRecord.rewardContent
                      }}
                    </div>
                  </div>
                  <div
                    v-if="
                      item.status == 12 &&
                      item.hasOwnProperty('handleRemark') &&
                      item.handleRemark != 'null'
                    "
                    class="handleRemark"
                  >
                    {{ item.handleRemark }}
                  </div>
                  <div
                    v-if="
                      item.status == 10 &&
                      item.hasOwnProperty('handleRemark') &&
                      item.handleRemark != 'null'
                    "
                    class="handleRemark1205s"
                  >
                    {{ item.handleRemark }}
                    <br />
                    <br />

                    接收单位:
                    <span style="color: black">{{
                      item.turnOverDeptName
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex; justify-content: right; margin-top: 10px">
      <div v-if="typedata.process !== 2 && typedata.sign !== 2">
        <el-button
          v-if="
            rowData.status == 18 || rowData.status == 14 || rowData.status == 12
          "
          @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button v-if="rowData.status == 18" type="primary">确 认</el-button>
      </div>
      <div>
        <el-button type="info" v-if="typedata.sign == 1" @click="toggle()"
          >移交事件</el-button
        >
        <el-button
          v-if="typedata.sign == 1"
          type="danger"
          @click="rejectDialog = true"
          >驳 回</el-button
        >
        <el-button
          v-if="typedata.sign == 1"
          type="primary"
          @click="signFor(rowData.without_repetition_id, rowData.id)"
          >签 收</el-button
        >
        <el-button
          v-if="typedata.process == 1"
          type="danger"
          @click="OverRejectDialog = true"
          >本人处理</el-button
        >
        <el-button
          v-if="typedata.process == 1"
          type="primary"
          @click="submit(rowData.id)"
          >他人协助处理</el-button
        >
        <el-button
          v-if="ziti.ziti == 1 && rowData.status == 18 && rewardButton"
          type="primary"
          @click="reward = true"
          >打赏积分</el-button
        >
      </div>
      <template>
        <p class="message-text" v-if="ziti.ziti == 2">
          您当前没有此事件的处理权限，只能查看处理的进度哦～
        </p>
      </template>
    </div>
    <el-dialog
      key="12313"
      title="移交事件"
      top="200px"
      :visible.sync="RejectPopup"
      :append-to-body="true"
      width="500px"
    >
      <el-form ref="form" :model="RejectForm" label-width="80px">
        <el-form-item label="接收单位" required>
          <deptTreeSelect
            style="margin-bottom: 20px"
            :Value="parentValue"
            @change="parentValue = $event"
            :options="treeData"
          ></deptTreeSelect>
        </el-form-item>
        <el-form-item label="移交理由" required>
          <el-input
            v-model="RejectForm.handleRemark"
            maxlength="500"
            type="textarea"
            placeholder="请输入移交理由"
          ></el-input>
        </el-form-item>
        <br />
        <div style="width: 100%; display: flex; justify-content: center">
          <el-button type="primary" @click="onSubmit(rowData.id)"
            >移交</el-button
          >
          <el-button @click="RejectPopup = false">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog
      title="打赏积分"
      key="1231233"
      top="200px"
      :visible.sync="reward"
      :append-to-body="true"
      @close="resetRewardForm"
      width="500px"
    >
      <el-form ref="form" :model="rewardForm" label-width="80px" :rules="rules">
        <el-form-item label="打赏方式">
          <el-select
            v-model="rewardForm.rewardMode"
            placeholder="打赏方式"
            style="margin-bottom: 10px"
            @change="rewardModeChange"
          >
            <el-option
              v-for="item in rewardModeDict"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="rewardPoint"
          v-if="rewardForm.rewardMode == 1"
          label="打赏分数"
        >
          <el-radio-group style="margin-bottom: 10px" v-model="pointOll">
            <el-radio-button label="1">1分</el-radio-button>
            <el-radio-button label="5">5分</el-radio-button>
            <el-radio-button label="10">10分</el-radio-button>
            <el-radio-button label="自定义">自定义</el-radio-button>
          </el-radio-group>
          <el-input
            v-model="pointOlv2l"
            v-if="pointOll == '自定义'"
            type="number"
            :min="1"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="rewardContent"
          v-if="rewardForm.rewardMode == 1"
          label="打赏内容"
        >
          <el-input
            type="textarea"
            v-model="rewardForm.rewardContent"
            style="margin-bottom: 10px"
            maxlength="100"
            placeholder="请输入打赏内容"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="rewardType"
          v-if="rewardForm.rewardMode == 2"
          label="打赏类别"
        >
          <el-select
            ref="selectRewardType"
            v-model="rewardForm.rewardTypeId"
            style="margin-bottom: 10px"
            placeholder="打赏类别"
            @change="rewardTypeChange"
          >
            <el-option
              v-for="item in rewardTypeDict"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          prop="rewardContent"
          v-if="rewardForm.rewardMode == 2"
          label="打赏内容"
        >
          <el-select
            ref="selectRewardContent"
            v-model="rewardForm.rewardContentId"
            style="margin-bottom: 10px"
            placeholder="打赏内容"
            @change="rewardContentChange"
          >
            <el-option
              v-for="item in rewardContentDict"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="rewardForm.rewardMode == 2" label="打赏积分">
          <el-input-number
            v-model="rewardForm.rewardPoint"
            :min="min"
            style="margin-bottom: 10px"
            :max="max"
          />
        </el-form-item>
      </el-form>

      <div style="width: 100%; display: flex; justify-content: center">
        <el-button type="primary" @click="rewardCommit">提 交</el-button>
        <el-button @click="disposeRewardDialog">取 消</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="驳回"
      key="1231244"
      top="200px"
      :visible.sync="rejectDialog"
      :append-to-body="true"
      @close="handleRemark = ''"
      width="500px"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="驳回意见" required>
          <el-input
            v-model="handleRemark"
            maxlength="500"
            type="textarea"
            placeholder="填写驳回意见"
          ></el-input>
        </el-form-item>
        <br />
        <div style="width: 100%; display: flex; justify-content: center">
          <el-button type="primary" @click="submitReject">确 定</el-button>
          <el-button
            @click="
              handleRemark = '';
              rejectDialog = false;
            "
            >取 消</el-button
          >
        </div>
      </el-form>
    </el-dialog>

    <el-dialog
      title="本人处理"
      key="1231255"
      top="200px"
      :visible.sync="OverRejectDialog"
      :append-to-body="true"
      @close="OverRejectRemark = ''"
      width="500px"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="结束意见" required>
          <el-input
            v-model="OverRejectRemark"
            maxlength="500"
            type="textarea"
            placeholder="填写结束意见"
          ></el-input>
        </el-form-item>
        <br />
        <div style="width: 100%; display: flex; justify-content: center">
          <el-button type="primary" @click="OverReject">确 定</el-button>
          <el-button
            @click="
              OverRejectRemark = '';
              OverRejectDialog = false;
            "
            >取 消</el-button
          >
        </div>
      </el-form>
    </el-dialog>



  </div>
</template>
<script>
import {
  getPropertyReportListParticulars,
  getPropertyRole,
  getPropertyRoles,
  rejectTaskPropertys,
  getCurrentUserDeptIsColleague,
  handOverTaskPropertys,
  updateXyPropertyEndCirculationHasRead,
  getCurrentUserDeptIsColleague2,
  getReflectDetail,
  updateXyPropertyCirculationHasRead,
} from '@/api/modules/policeCirculation';
import DeptTreeSelect from '@/views/system/components/deptTreeSelect.vue';
import { getCustomDeptViewId } from '@/api/modules/overviewProMax';

import {
  rewardType,
  rewardContent,
  saveReward,
  rewardPointRange,
  getRewardRecord,
} from '@/api/modules/reward';
export default {
  name: 'PublishTaskDetail',
  components: {
    DeptTreeSelect,
  },
  props: {
    // dialogVisible: {
    //   type: Boolean,
    // },
    detailId: {
      type: String,
    },
    rowData: {
      type: Object,
      default: {},
    },
    // rewardRecord: {
    //   type: Object,
    //   default: () => {
    //     return {};
    //   },
    // },
  },
  data() {
    return {
      treeData: [],
      parentValue: '',
      OverRejectRemark: '',
      OverRejectDialog: false,
      handleRemark: '',
      rejectDialog: false,
      reward: false,
      RejectPopup: false,
      rewardButton: false,
      rewardTypeDict: [],
      rewardContentDict: [],
      rewardRecord: { id: null },
      rules: {
        rewardContent: [
          { required: true, message: '请打赏内容', trigger: 'blur' },
        ],
        rewardType: [
          { required: true, message: '请打赏类别', trigger: 'blur' },
        ],
        // rewardPoint: [{required: true, message: '打赏分数大于0', validator: validatePonits,trigger: 'blur'}
        // ],
      },
      RejectForm: {
        reflectDeptId: '',
        handleRemark: '',
      },
      rewardForm: {
        bizId: '',
        source: 'intelligence',
        rewardMode: 1,
        rewardType: '',
        rewardTypeId: '',
        rewardContent: '',
        rewardContentId: '',
        rewardPoint: 1,
      },
      rewardModeDict: [
        { label: '自定义分数', value: 1 },
        { label: '指定分数', value: 2 },
      ],
      feedbacks: {
        fileIds: [],
        feedbackContent: '',
        xyPoliceCirculationId: '',
        reply: '',
        feedbackTel: this.$store.state.user.mobile,
        feedbackUserName: this.$store.state.user.name,
        feedbackUserId: sessionStorage.getItem('userId'),
      },
      myDivHeight: 0,
      a: [],
      b: [],
      c: [],
      pointOll: '1',
      pointOlv2l: '1',
      ReflectStatusList: [],
      img1: require('@/assets/待处理.png'),
      img2: require('@/assets/已驳回.png'),
      img4: require('@/assets/已签收.png'),
      submitAudioFileUrls: [],
      submitFileUrls: [],
      submitVideoFileUrls: [],
      dataId: '',
      form: {},
      reverse: true,
      typedata: [],
      ziti: {},
    };
  },
  watch: {
    pointOll(val) {
      if (val != '自定义') {
        this.pointOlv2l = '1';
      }
    },
  },
  created() {
    this.dataId = this.detailId;

    console.log('rowData', this.rowData);

    rewardType().then((res) => {
      this.rewardTypeDict = res.data;
    });

    this.getDetailInfo(this.dataId);
  },
  mounted() {},
  methods: {
    disposeRewardDialog() {
      this.reward = false;
      this.resetRewardForm();
    },
    resetRewardForm() {
      (this.pointOll = '1'),
        (this.rewardForm = {
          bizId: '',
          source: 'intelligence',
          rewardMode: 1,
          rewardType: '',
          rewardTypeId: '',
          rewardContent: '',
          rewardContentId: '',
          rewardPoint: 1,
        });
    },
    //他人协助处理
    submit(id) {
      getReflectDetail(id).then((res) => {
        console.log(res, 'res');
        console.log(res.data.submitDesc, 'res.data.submitDesc');
        console.log(this.oldStatus, 'oldStatus');
        if (res.data.status !== this.oldStatus) {
          this.$message({
            message: '该事件已被他人操作，请刷新后重试',
            type: 'warning',
          });
        } else {
          this.$emit('refresh');
          this.$router.push({
            path: '/policeCirculation/PublishTaskAdd',
            query: {
              data: {
                withoutRepetitionId: this.detailId,
                submitDesc: res.data.submitDesc,
                address: res.data.address,
                detailedAddress: res.data.detailedAddress,
                police_type: res.data.taskType,
                task_type: res.data.taskTypeId,
              },
            },
          });
        }
      });
    },
    //本人处理
    OverReject() {
      getReflectDetail(this.rowData.id).then((res) => {
        console.log(this.oldStatus, 'oldStatus');
        if (res.data.status !== this.oldStatus) {
          this.$message({
            message: '该事件已被他人操作，请刷新后重试',
            type: 'warning',
          });
        } else {
          if (this.OverRejectRemark.trim() == '') {
            this.$message({
              message: '请填写结束意见',
              type: 'warning',
            });
            return;
          }
              let data = {
                withoutRepetitionId: this.detailId,
                handleRemark: this.OverRejectRemark,
              };
              updateXyPropertyEndCirculationHasRead(data).then((res) => {
                console.log(data, '111');

                this.$message({
                  message: '事件结束成功',
                  type: 'success',
                });

                this.$emit('refresh');
              });
        }
      });
    }, 
    rewardCommit() {
      //校验积分打赏表单
      console.log('积分打赏提交', this.rewardForm);
      console.log('积分打赏提交', this.pointOll);
      if (this.rewardForm.rewardMode == 1) {
        if (this.pointOll != '自定义') {
          this.rewardForm.rewardPoint = this.pointOll;
        } else {
          if (this.rewardForm.rewardPoint === '') {
            this.$message({
              message: '请输入打赏积分',
              type: 'warning',
            });
            return;
          }
          this.rewardForm.rewardPoint = this.pointOlv2l;
        }
        console.log('积分打赏提交2', this.rewardForm);
      }
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.rewardForm.bizId = this.detailId;
          saveReward(this.rewardForm).then((res) => {
            if (res.code == 200) {
              this.reward = false;
              this.dialogVisible = false;
              //打赏成功后恢复表单
              this.resetRewardForm();
              this.$message({
                message: '积分打赏成功',
                type: 'success',
              });
              this.$emit('refresh');
            }
          });
        } else {
          return false;
        }
      });
    },
    rewardTypeChange() {
      this.$nextTick(() => {
        console.log(this.$refs.selectRewardType.selectedLabel);
        this.rewardForm.rewardType = this.$refs.selectRewardType.selectedLabel;
      });
      (this.rewardForm.rewardContentId = ''),
        rewardContent(this.rewardForm.rewardTypeId).then((res) => {
          this.rewardContentDict = res.data;
        });
    },
    rewardContentChange() {
      this.$nextTick(() => {
        console.log(this.$refs.selectRewardContent.selectedLabel);
        this.rewardForm.rewardContent =
          this.$refs.selectRewardContent.selectedLabel;
      });
      rewardPointRange(this.rewardForm.rewardContentId).then((res) => {
        this.min = res.data.min;
        this.max = res.data.max;
        this.rewardForm.rewardPoint = this.min;
      });
    },
    rewardModeChange() {
      this.rewardForm.rewardType = '';
      this.rewardForm.rewardContent = '';
    },
    signFor(withoutRepetitionId, id) {
      getReflectDetail(id).then((res) => {
        console.log(res.data.status, 'status');
        console.log(this.oldStatus, 'oldStatus');
        if (res.data.status === this.oldStatus) {
          updateXyPropertyCirculationHasRead(withoutRepetitionId).then(
            (res) => {
              if (res.code === 200) {
                this.rowData = res.data;
                this.drawer = false;
                this.$message({
                  message: '签收成功',
                  type: 'success',
                });
                this.dialogVisible = false;
                this.$emit('refresh');
              }
            }
          );
        } else {
          this.$message({
            message: '该事件已被他人操作，请刷新后重试',
            type: 'warning',
          });
        }
      });
    },
    feedbackSub() {
      signAndFeedbackTaskProperty(this.feedbacks).then((res) => {
        if (res.code == 200) {
          this.rowData = res.data;
          this.drawer = false;
          this.$message({
            message: '反馈成功',
            type: 'success',
          });
          this.$emit('refresh');

          if (this.myUpload != null) {
            this.myUpload.clearFiles();
          }
          (this.feedbacks.fileIds = []),
            (this.feedbacks.feedbackContent = ''),
            (this.feedbacks.xyPoliceCirculationId = ''),
            (this.feedbacks.reply = '');
        }
      });
    },
    // 驳回轻薄
    submitReject() {
      getReflectDetail(this.rowData.id).then((res) => {
        console.log(this.oldStatus, 'oldStatus');
        if (res.data.status !== this.oldStatus) {
          this.$message({
            message: '该事件已被他人操作，请刷新后重试',
            type: 'warning',
          });
        } else {
          if (this.handleRemark.trim() == '') {
            this.$message({
              message: '请填写驳回意见',
              type: 'warning',
            });
            return;
          }

          let data = {
            withoutRepetitionId: this.detailId,
            handleRemark: this.handleRemark,
          };

          rejectTaskPropertys(data).then((res) => {
            console.log(res);
            this.$message({
              message: res.data,
              type: 'success',
            });
            this.handleRemark = '';
            this.rejectDialog = false;

            this.$emit('refresh');
          });
        }
      });
    },
    onSubmit(id) {
      getReflectDetail(id).then((res) => {
        console.log(res.data.status, 'status');
        console.log(this.oldStatus, 'oldStatus');
        if (res.data.status !== this.oldStatus) {
          this.$message({
            message: '该事件已被他人操作，请刷新后重试',
            type: 'warning',
          });
        } else {
          this.RejectForm.reflectDeptId = this.parentValue;
          if (
            this.RejectForm.reflectDeptId == sessionStorage.getItem('deptId')
          ) {
            this.$message({
              message: '不能移交给自己',
              type: 'warning',
            });
            return;
          }
          if (
            !this.RejectForm.reflectDeptId ||
            this.RejectForm.reflectDeptId == ''
          ) {
            this.$message({
              message: '请选择移交单位',
              type: 'warning',
            });
            return;
          }
          if (
            this.RejectForm.handleRemark === null ||
            this.RejectForm.handleRemark == ''
          ) {
            this.$message({
              message: '请输入移交原因',
              type: 'warning',
            });
            return;
          }
          // return;
          let handOver = {
            reflectDeptId: this.RejectForm.reflectDeptId,
            withoutRepetitionId: this.detailId,
            handleRemark: this.RejectForm.handleRemark,
          };

          handOverTaskPropertys(handOver).then((res) => {
            (this.RejectForm = {
              reflectDeptId: '',
              handleRemark: '',
            }),
              this.$message({
                message: res.data,
                type: 'success',
              });
            this.RejectPopup = false;
            this.$emit('refresh');
          });
        }
      });
    },
    toggle() {
      this.parentValue = [];
      this.RejectForm.handleRemark = '';
      getCustomDeptViewId(this.$store.getters.deptId).then((res) => {
        this.parentValue = [sessionStorage.getItem('customViewDeptId')];
        this.treeData = res.data;
        this.RejectPopup = true;
      });
    },
    async getPropertyRole() {
      const params = {
        withoutRepetitionId: this.dataId,
      };
      const res = await getPropertyRole(params);
      // this.typedata = res.data;
      // this.$emit('typedata', res.data);
      // console.log(res, 'typedata');
    },
    async getPropertyRoles() {
      const params = {
        withoutRepetitionId: this.dataId,
        status: this.form.status,
      };
      const res = await getPropertyRoles(params);
      this.ziti = res.data;
      if (this.rowData.status == 18) {
        getRewardRecord({ source: 'intelligence', bizId: this.detailId }).then(
          (res) => {
            if (res.data && res.data.length == 0) {
              this.rewardButton = true;
            } else {
              this.rewardButton = false;
              this.rewardRecord = res.data[0];
            }
            console.log(this.rewardButton);
            console.log(this.rowData.status == 18);
            console.log(this.ziti.ziti);

            console.log(
              this.ziti.ziti == 1 &&
                this.rowData.status == 18 &&
                this.rewardButton
            );
          }

          // v-if="ziti.ziti == 1 && rowData.status == 18 && rewardButton"
        );
      }
      console.log(res, 'ziti');
    },
    setTime(val) {
      let time = val.substring(5).replace('-', ' / ');
      return time;
    },
    async getDetailInfo(id) {
      let reflectDept = {
        withoutRepetitionId: id,
      };
      // 判断进度条

      const resData = await getPropertyReportListParticulars(reflectDept);

      console.log(resData.data);
      this.policeCirculationOtherInformationDtos =
        resData.data.policeCirculationOtherInformationDtos[0];
      this.submitAudioFileUrls = resData.data.submitAudioFileUrls;
      this.submitVideoFileUrls = resData.data.submitVideoFileUrls;
      this.submitFileUrls = resData.data.submitFileUrls;
      this.form = resData.data.policeCirculationListDtos[0];

      if(this.rowData.populationId !== undefined){
        this.rowData.status = this.form.status;
      }

      if(this.rowData.without_repetition_id == undefined){
        this.rowData.without_repetition_id = this.form.withoutRepetitionId;
      }
      this.oldStatus = this.rowData.status;

      this.ReflectStatusList = resData.data.policeCirculationOtherInformationDtos;

      this.a = resData.data.fanYingObj

      this.c = resData.data.notFanYingObj;

      this.getPropertyRole();
      this.getPropertyRoles();

      if (this.form.status) {
        if (this.form.status == 1) {
          this.form.statusName = '待反馈';
        } else if (this.form.status == 2) {
          this.form.statusName = '已完成';
        } else if (this.form.status == 12) {
          this.form.statusName = '被驳回';
        }
        if (this.form.status == 10) {
          this.form.statusName = '已移交';
        }
        if (this.form.status == 14) {
          this.form.statusName = '已撤销';
        }
      }
      this.typedata = resData.data.processModeDto;

      this.$nextTick(() => {
        console.log(this.$refs.form.$el.offsetHeight, '开开开');
        let height = this.$refs.form.$el.offsetHeight;
        this.myDivHeight = height - 70;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.content {
  float: left;
}
.bootContent {
  .statustitle {
    padding: 12px 0;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.9);
    line-height: 16px;
    .titlest {
    }
  }
}
.statust1 {
  font-size: 8px !important;
  padding: 2px 4px;
  color: #0148a4;
  width: 1.83rem;
  border: 0.5px solid #0148a4;
}
.statust2 {
  width: 1.83rem;
  font-size: 8px !important;
  padding: 2px 4px;
  color: #00a870;
  border: 0.5px solid #00a870;
}
.status12 {
  width: 1.83rem;
  font-size: 8px !important;
  padding: 2px 4px;
  color: #ff6a6a;
  border: 0.5px solid #ff6a6a;
}
.status10 {
  width: 1.83rem;
  font-size: 8px !important;
  padding: 2px 4px;
  color: #7fa2d1;
  border: 0.5px solid #7fa2d1;
}
.status14 {
  width: 1.83rem;
  font-size: 8px !important;
  padding: 2px 4px;
  color: #000000a1;
  border: 0.5px solid #000000a1;
}
.status18 {
  width: 1.83rem;
  font-size: 8px !important;
  padding: 2px 4px;
  color: #0148a4;
  border: 0.5px solid #0148a4;
}
.stepItem {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  border-bottom: 1px solid #e7e7e7;

  .taptitle {
    display: flex;

    .img {
      width: 22px;
      height: 22px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    span {
      line-height: 1.88rem;
      // padding-left: 1rem;
    }
  }
}
.span {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  line-height: 16px;
}
.stepBox {
  // border-left: 0.15rem solid #c5c5c5;
  // margin-left: 0.6rem;
  // padding-left: 1.7rem;
  // padding-top: 0.65rem;
  // padding-bottom: 0.65rem;
  padding: 1rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  // line-height: 1.83rem;
  .handleRemark1205s {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    padding: 8px 0;
    color: rgba(0, 0, 0, 0.9);
    // line-height: 14px;
  }
  .gagaga {
    margin-top: 30px;
    .Itemgaga {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      .deptNamegaga {
        color: black;
      }
      .timegaga {
        color: rgba(0, 0, 0, 0.6);
      }
    }
    .handleRemarkGaga {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      padding: 8px 0;
      color: rgba(0, 0, 0, 0.9);
    }
  }
  .Item1205s {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    .deptName1205s {
      color: black;
      font-weight: 600;
    }
    .time1205s {
      color: rgba(0, 0, 0, 0.6);
    }
  }
}
.bdn {
  .stepBox {
    border-left: none !important;
  }
}
/deep/ .el-image-viewer__wrapper {
  z-index: 2221 !important;
}
/deep/ .el-form-item {
  margin-bottom: 2px !important;
}
.Processing {
  border: 1px solid #e7e7e7ff;
  overflow-y: auto;
  margin-top: 30px;
  min-height: 500px;
}
</style>
