<template>
  <div class="_waterMask"></div>
</template>
<script>
export default {
  props: {
    //是否允许通过js或者开发者工具等途径修改水印DOM节点（水印的id，attribute属性，节点的删除）
    //true为允许，默认不允许
    inputAllowDele: {
      type: Boolean,
      default: false,
    },
    //是否在销毁组件时去除水印节点（前提是允许用户修改DOM，否则去除后会再次自动生成）
    // true会，默认不会
    inputDestroy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawer: true,
      maskDiv: {}, //当前显示的水印div节点DOM对象
      initProps: {
        userName: '', //显示的水印文本
        areaName: '', // 辖区名字
        number: '', // 数字
        width: '300', // 单个水印的宽度
        height: '230', // 单个水印的宽度
        fillStyle: 'rgb(112, 113, 114, 0.2)', // 水印颜色
        rotateNumber: -25, // 旋转角度
        zIndex: '3000', // 水印的层级
      },
    };
  },

  created() {

    let data = JSON.parse(sessionStorage.getItem('watermark'));

    if (data) {
      this.initProps.userName = data.userName ||'';
      this.initProps.areaName = data.deptName ||'';
      data.mobile = data.mobile.substr(-4) ||'';
      this.initProps.number = data.mobile ||'';
    }

  },
  mounted() {
    //确认DOM渲染后再执行
    this.$nextTick(() => {
      //创建水印节点
      this.init();
      if (!this.inputAllowDele) {
        // 设置水印节点修改的DOM事件
        this.Monitor();
      }
    });
  },
  methods: {
    init() {
      let canvas = document.createElement('canvas');
      canvas.id = 'canvas';
      canvas.width = this.initProps.width; // 单个水印的宽高
      canvas.height = this.initProps.height;
      this.maskDiv = document.createElement('div');
      let ctx = canvas.getContext('2d');
      ctx.font = 'normal 18px Microsoft Yahei'; // 设置样式
      ctx.fillStyle = this.initProps.fillStyle; // 水印字体颜色

      // 计算旋转角度并处理负值的情况
      const angle = (this.initProps.rotateNumber * Math.PI) / 180;
      ctx.translate(canvas.width / 2, canvas.height / 2); // 将画布原点移到中心
      ctx.rotate(angle); // 旋转画布

      // 拆分水印文本
      const waterMarkTextArr = [
        `${this.initProps.areaName}`,
        `${this.initProps.userName} ${this.initProps.number}`,
      ];

      // 计算文本的起始Y轴位置，使其居中
      const textHeight = 20; // 每行高度
      const startY = -((waterMarkTextArr.length - 1) * textHeight) / 2; // 计算起始Y轴位置

      // 按行绘制水印文本
      waterMarkTextArr.forEach((text, index) => {
        const textWidth = ctx.measureText(text).width;
        ctx.fillText(text, -textWidth / 2, startY + index * textHeight);
      });

      let src = canvas.toDataURL('image/png');
      this.maskDiv.style.position = 'fixed';
      this.maskDiv.style.zIndex = this.initProps.zIndex; // 水印的层级
      this.maskDiv.id = '_waterMark';
      this.maskDiv.style.top = '80px';
      this.maskDiv.style.left = '0px';
      this.maskDiv.style.width = '100%';
      this.maskDiv.style.height = '100%';
      this.maskDiv.style.pointerEvents = 'none';
      this.maskDiv.style.backgroundImage = 'URL(' + src + ')';
      document.getElementsByClassName('watermask')[0].appendChild(this.maskDiv);
    },
    Monitor() {
      let body = document.getElementsByClassName('watermask')[0];
      // let body = document.getElementsByTagName('body')[0]
      let options = {
        childList: true,
        attributes: true,
        characterData: true,
        subtree: true,
        attributeOldValue: true,
        characterDataOldValue: true,
      };
      let observer = new MutationObserver(this.callback);
      observer.observe(body, options); //监听父节点， 强制删除则重新创建
    },
    //DOM改变执行callback
    callback(mutations, observer) {
      //当attribute属性被修改
      if (mutations[0].target.id === '_waterMark') {
        this.removeMaskDiv();
      }
      //当id被改变时
      if (mutations[0].attributeName === 'id') {
        this.removeMaskDiv();
        this.init();
      }
      //当节点被删除
      if (
        mutations[0].removedNodes[0] &&
        mutations[0].removedNodes[0].id === '_waterMark'
      ) {
        this.init();
      }
    },
    /* public */
    //手动销毁水印DOM
    removeMaskDiv() {
      // document.body.removeChild(this.maskDiv)
      document.getElementsByClassName('watermask')[0].removeChild(this.maskDiv);
    },
    //手动生成水印
    createMaskDiv() {
      this.init();
    },
  },
  watch: {
    //监听传入水印文本变化
    inputText() {
      this.$nextTick(() => {
        this.removeMaskDiv();
      });
    },
  },
  destroy() {
    //组件销毁时去除生成在body节点下的水印节点
    if (this.inputDestroy) {
      this.removeMaskDiv();
    }
  },
};
</script>

<style lang="less">
._waterMask {
  #settingBtn {
    position: absolute;
    bottom: 20px;
    right: 0;
    font-size: 35px;
  }
}
</style>
