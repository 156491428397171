<template>
  <Treeselect
    v-model="value"
    :options="options"
    :multiple="multiple"
    :placeholder="placeholder"
    :normalizer="tenantIdnormalizer"
    :autoSelectDescendants="false"
    :flat="true"
    @input="treeSelectInput"
    @select="treeSelectChange"
    @deselect="treeSelectDeselect"
    @search-change="treeSelectSearch"
    @open="treeSelectOpen"
    :clearable="false"
    @close="treeSelectClose"
  />
</template>
<script>
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
export default {
  props: {
    multiple:{
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    Value: {
      type: [String, Number],
      default: [],
    },
    placeholder:{
      type: String,
      default: '请选择辖区',
    },
  },
  watch: {
    Value: {
      handler(val) {
        this.$nextTick(() => {
          if (val !== '') {
            this.value = val;
          }
          console.log(this.value, '回显赋值');
        });
      },
      immediate: true,
    },
  },
  data() {
    return {
      value: null,
    };
  },
  components: { Treeselect },
  mounted() {
    // // 延迟模拟请求数据
    // setTimeout(() => {
    //   this.options = treeData
    //   this.value = [111, 113] // 测试回显操作
    // }, 300)
  },
  methods: {
    // 选中触发（第一次回显的时候会触发，清除值的时候会触发, value值为undefined） input事件用于v-model双向绑定组件更新父组件值
    treeSelectInput(value, instanceId) {
      console.log(value, 'input事件');
      console.log(this.value, 'this.value -- input');
      if (value === undefined) {
        this.value = '';
      }
      this.$emit('change', this.value);
      // 这个不需要 延迟
    },
    // 选中触发（清除值的时候不会触发）
    treeSelectChange(raw, instanceId) {
      console.log(raw, '当前的对象');
      sessionStorage.setItem('changeDeptName', raw.name)
      setTimeout(() => {
        // 如果用到this.value 需要setTimeout延迟一下拿到最新的值
        console.log(this.value, 'this.value -- select');
        this.$emit('change2', this.value,raw);
      });
    },
    // 移除选项时触发 当设置multiple为true时生效  raw为当前移除的对象
    treeSelectDeselect(raw, instanceId) {
      console.log(raw, 'deselect-->>');
      this.value=this.value.filter(item => item != raw.id)
      //会先后删除不生效
      this.$emit('change',this.value); //
    },
    // 搜索
    treeSelectSearch(searchQuery, instanceId) {
      console.log(searchQuery, '当前搜索的值');
    },
    // 展开触发
    treeSelectOpen(instanceId) {
      console.log('展开了');
    },
    // 关闭触发
    treeSelectClose(value, instanceId) {
      console.log(value, '当前的value值');
    },
    // 字段默认 id label 用于规范化数据源
    tenantIdnormalizer(node, instanceId) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.name || node.deptName,
        children: node.children,
      };
    },
  },
};
</script>
<style scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 60px 0 0 200px;
}

.main .tree {
  width: 240px;
  height: 40px;
}

::v-deep .vue-treeselect__label {
  /* color: #606266; */
}
</style>
